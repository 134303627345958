import React from 'react'

import ScrollToTop from "react-scroll-to-top";

import {DatePicker}  from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import startOfWeek from "date-fns/startOfWeek";
import es from "date-fns/locale/es";


import { useNavigate } from 'react-router';
import AllMusicAlbumMusicCard from '../AllMusicAlbumMusicCard';
import { AllMusicAlbumProperties, SpotifyAlbum } from '../types';
import { useLocation } from 'react-router';

// Convert string with format YYYYMMdd to
// a Date object
// If d is wrong date, return last sunday
function dateFromString(d: string) {
  let convertedDate = startOfWeek(new Date(parseInt(d?.substring(0, 4)), parseInt(d?.substring(4, 6)) - 1, parseInt(d?.substring(6, 8))))
  if (convertedDate instanceof Date && !isNaN(convertedDate.getTime())) {
    return convertedDate
  }

  const now = new Date()
  // startofweek considers sunday as the first day of the week (this is configurable
  //  with weekStartsOn option).
  // but in international standard sunday will be the last day of the week. So we're
  //  actually considering the last day of *past week* that is what we want.
  const aWeekBefore = startOfWeek(new Date(now.setDate(now.getDate())))
  return aWeekBefore

}

// returns a spotify album (album_type==album) if it exists
// from an array. Or the first element in the array (whatever it is)
// if not.
export function findSpotifyAlbum(items: SpotifyAlbum[]) {
  let ret = items.find((album) => {
    if (album.album_type === "album") {
      return true
    }
    return false
  })

  // Default to first item album, single or whatever
  if (!ret) {
    ret = items[0];
  }
  return ret
}

// Convert a Date object to a string date with
//  format YYYYMMdd considering timezone offset
function dateToString(d: Date) {
  let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  return (new Date(d?.getTime() - tzoffset)).toISOString().split('T')[0].replaceAll("-", "")
}

function AllMusicAlbums ({date}:{date: string}) {

  let history = useNavigate()
  const [token, setToken] = React.useState("")

  React.useEffect(() => {
    fetch("/token").then(response => response.text()).then(txt => setToken(txt))
    // return () => {
    //   cleanup
    // }
  }, [])

  let initalDate = dateFromString(date)

  // Default date: a week before
  const [startDate, setStartDate] = React.useState<Date>(initalDate);
  const [albums, setAlbums] = React.useState<(SpotifyAlbum & AllMusicAlbumProperties)[]>([])


  React.useEffect(() => {
    async function fetchData() {
      // toISOString is in UTC. We have to consider offset so we don't get
      //  the previous day
      let tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
      const dateURL = (new Date(dateFromString(date).getTime() - tzoffset)).toISOString().split('T')[0]

      let response1 = await fetch("/api/in/allmusic/newfeaturedreleases?date=" + dateURL)
      let json1 = await response1.json()

      let allmusicAlbums = [...json1]
      if (token !== "") {
        let spotifyAlbums = await Promise.all(allmusicAlbums.map(async (element) => {
          const response2 = await fetch(encodeURI("https://api.spotify.com/v1/search?type=album&q=" + element.Title + " " + element.Artists[0]), {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          })
          if (response2.status === 401) {
            console.log("entra")
            fetch("/token").then(response => response.text()).then(txt => setToken(txt))
          }
          const json2 = await response2.json()

          let definitiveSpotifyAlbum = findSpotifyAlbum(json2.albums?.items)

          if (definitiveSpotifyAlbum) {
            // Populate release year
            definitiveSpotifyAlbum.release_year = parseInt(definitiveSpotifyAlbum?.release_date.substring(0, 4))
            // Album must have the same date
            console.log("Spotify year", definitiveSpotifyAlbum.release_year)

          }

          element.Rating = element.Rating + " / 5"
          const enhancedAlbum  = {...definitiveSpotifyAlbum, ...element}
          return enhancedAlbum
        }));

        const enhancedAlbums = spotifyAlbums.filter((item) => item.id !== undefined && item.id !== "" && item.release_year === startDate.getFullYear())

        setAlbums(enhancedAlbums)
      }
    }
    fetchData();

    // return () => {
    //   cleanup
    // }
  }, [token, date])

  let location = useLocation()

  return (
    <>
      <ScrollToTop smooth
        color="rgb(0, 123, 255)"
        svgPath="M12 3.172L5.586 9.586a2 2 0 1 0 2.828 2.828L10 10.828v7.242a2 2 0 0 0 4 0v-7.242l1.586 1.586c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L12 3.172z"
        viewBox="0 0 20 20"
        style={{ backgroundColor: "transparent" }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <DatePicker
          label="Select date"
          value={startDate}
          onChange={(date: Date | null) => {
            const dateNotNull : Date = date ? date : new Date()
            setStartDate(startOfWeek(dateNotNull))
            const dateURL = dateToString(startOfWeek(dateNotNull))
            history(location.pathname.replace(/\/allmusic.*/, "/allmusic/" + dateURL))
          }}
          format="dd/MM/yyyy"
          showDaysOutsideCurrentMonth
          className="mb-4"
          views={["year", "month","day"]}
        />
        {/* <DatePicker selected={startDate} onChange={date => setStartDate(date)} /> */}
        <div className="container">
          <div className="row">
            {albums.map(item => {
              return (
                  <AllMusicAlbumMusicCard
                    key={item.id}
                    album={item}
                  />
              )
            })}
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
}


export default AllMusicAlbums;
