import MusicCard from './MusicCard';
import { SimpleTrackPlayer } from './SimpleTrackPlayer';
import { Link } from 'react-router-dom';
import { SpotifyTrack } from './types';
import { IconButton } from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addAlbumAsPlayList } from './api';
import EnqueueAlbum from './components/EnqueueAlbum';

export default function TrackMusicCard({track}: {track : SpotifyTrack}) {

    return (
        <MusicCard
            key={track.id}
            image={{src: track.album.images[0]?.url}}
            title={track.name}
            properties={[
                {
                    name: 'Album',
                    value: <>
                        <Link title="" to={"/player?s=" + encodeURIComponent("artist:\"" + track.artists[0].name + "\" album:\"" + track.album.name + "\"")}>{track.album.name}</Link>
                        <IconButton title="Create playlist" color="primary" onClick={() => addAlbumAsPlayList(track.album.id)}>
                            <AddCircleOutlineIcon/>
                        </IconButton>
                        <EnqueueAlbum spotifyId={track.album.id}/>
                        </>
                },
                {
                    name: 'Artists',
                    value: <>{track.artists.map((a) => <Link title="" to={"/player?s=" + encodeURIComponent("artist:\"" + a.name + "\"")}>{a.name}</Link>).reduce((prev, curr) => <>{prev}, {curr}</>)}</>
                }
            ]}
        >
            <SimpleTrackPlayer spotifyId={track.id}></SimpleTrackPlayer>
        </MusicCard>
    )
}