import { IconButton } from "@mui/material"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import {
    playSpotifyAlbum,
} from './spot';
import EnqueueAlbum from "./components/EnqueueAlbum";
import NextTrackButton from "./components/NextTrackButton";
import PauseButton from "./components/PauseButton";
import { useSnackBarHandleResponse } from "./hooks";

export function SimpleAlbumPlayer({spotifyId}:{spotifyId:string}) {

    const {onlyErrorHandler} = useSnackBarHandleResponse()

    return (
        <p>
            <PauseButton/>
            <IconButton title="Play" color="primary" onClick={() => playSpotifyAlbum(spotifyId, onlyErrorHandler)}>
                <PlayArrowIcon></PlayArrowIcon>
            </IconButton>
            <NextTrackButton/>
            <EnqueueAlbum spotifyId={spotifyId}/>
        </p>
    )
}