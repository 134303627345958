import React, { KeyboardEvent } from 'react'
import {searchPaginatedSongs} from './spot'
import {SpotifyPagedTracksResult, SpotifyTrack} from './types'
import ScrollToTop from "react-scroll-to-top";

import { Pagination } from '@mui/material';

import {useNavigate, useLocation} from 'react-router'
import TrackMusicCard from './TrackMusicCard';
import { IconButton, Input, InputAdornment } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import { useSnackBarHandleResponse } from './hooks';

const KEY_ENTER = "Enter"

function SongsList () {

    const {resultHandler} = useSnackBarHandleResponse()

    const ITEMS_PER_PAGE=20
    const ITEMS_OFFSET=20

    let history = useNavigate()
    const location = useLocation()

    const pressEnter = async (event: KeyboardEvent)  => {
      if (event.code === KEY_ENTER) {
        setSearchTerm(refInput.current?.value ? refInput.current?.value : "")
        setPageNumber(1)
      }
    }

    const clearSearch = () => {
      if (refInput?.current != null) {
        refInput.current.value = ""
      }

      history("/player")
      // If the search Term is "" there's no sense in having pagination
      // since it's not going go work.
      // BUT we show the last page shown anyway: that's why we
      // don't do setSearchTerm()
      setTotalItems(0)
    }

    const refInput = React.useRef<HTMLInputElement>(null)
    const [tracks, setTracks] = React.useState<SpotifyTrack[]>([])

    const searchTermURL = new URLSearchParams(location.search).get("s")
    const [searchTerm, setSearchTerm] = React.useState<string|null>(searchTermURL)

    const pageNumberURL = parseInt(new URLSearchParams(location.search).get("page") || '1', 10)

    const [totalItems, setTotalItems] = React.useState(0)
    const [pageNumber, setPageNumber] = React.useState(pageNumberURL)

    React.useEffect(() => {
      const searchTermURL = new URLSearchParams(location.search).get("s")
      setSearchTerm(searchTermURL)
      const pageNumberURL = parseInt(new URLSearchParams(location.search).get("page") || '1', 10)
      setPageNumber(pageNumberURL)
    }, [location])

    React.useEffect(() => {
      let search = async() => {
        if (searchTerm != null && searchTerm !== ""){
          let tracks: SpotifyPagedTracksResult = await searchPaginatedSongs(searchTerm, (pageNumber-1) * ITEMS_OFFSET, ITEMS_PER_PAGE, resultHandler)
          setTracks(tracks.items)
          setTotalItems(tracks.total)

          if (refInput?.current != null) {
            refInput.current.value = searchTerm
          }

          history("/player?s="+encodeURIComponent(searchTerm.trim())+"&page=" + pageNumber)
        }

      }
      search()
    }, [searchTerm, pageNumber, history])

    return (
        <>
        <ScrollToTop smooth
          color="rgb(0, 123, 255)"
          svgPath="M12 3.172L5.586 9.586a2 2 0 1 0 2.828 2.828L10 10.828v7.242a2 2 0 0 0 4 0v-7.242l1.586 1.586c.391.391.902.586 1.414.586s1.023-.195 1.414-.586a2 2 0 0 0 0-2.828L12 3.172z"
          viewBox="0 0 20 20"
          style={{ backgroundColor: "transparent" }}
        />
        <div className="container">
            <div className="form-group">
            <Input
              onKeyUp={pressEnter}
              inputRef={refInput}
              className="form-control"
              type="text"
              id="search"
              placeholder="Spotify Search"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <Cancel/>
                  </IconButton>
                </InputAdornment>
              }
              autoFocus/>
            <button
              onClick={async() => {setSearchTerm(refInput.current?.value ? refInput.current?.value : "");setPageNumber(1)}}
              type="button" id="buttonSearch" className="btn btn-primary btn">Search</button>
            </div>
        </div>
        <Pagination
          count={Math.ceil(totalItems/ITEMS_PER_PAGE)}
          page={pageNumber}
          onChange={(object, page) => setPageNumber(page)}
          variant='outlined'
          size='large'
          style={{display: 'inline-block', margin: '1%'}}
          hidePrevButton={totalItems===0}
          hideNextButton={totalItems===0}
        />
        <div className="container">
            <div className="row">
            {tracks?.map(item => {
            console.log(item.id)
            return <TrackMusicCard key={item.id} track={item}/>
            })}
        </div>
        <Pagination
          count={Math.ceil(totalItems/ITEMS_PER_PAGE)}
          page={pageNumber}
          onChange={(object, page) => setPageNumber(page)}
          variant='outlined'
          size='large'
          style={{display: 'inline-block', margin: '1%'}}
          hidePrevButton={totalItems===0}
          hideNextButton={totalItems===0}
        />
      </div>
      </>
    );
}


export default SongsList;
