import { SnackbarOrigin } from "@mui/material";
import { OptionsObject, useSnackbar } from "notistack";
import { resultType } from "./types";

export function useSnackBarHandleResponse() {
    const {enqueueSnackbar} = useSnackbar()

    const commonAnchorOrigin: SnackbarOrigin = {
      vertical: 'bottom',
      horizontal: 'center',
    }

    const commonOptions: OptionsObject = {
      anchorOrigin: commonAnchorOrigin,
      // style: {whiteSpace: 'pre-line'},
    }
    const resultHandler = (r:resultType) => {
        switch(r.type) {
          case "Ok":
            enqueueSnackbar(r.message, {
              variant: 'success',
              ...commonOptions,
            })
            break;
            default:
              enqueueSnackbar(r.message, {
                variant: 'error',
                ...commonOptions,
              })
        }
      }
      const onlyErrorHandler = (r:resultType) => {
        if (r.type === "Error") {
          enqueueSnackbar(r.message, {
            variant: 'error',
            ...commonOptions,
          })
        }
      }
      const infoSender = (message: string) => {
        enqueueSnackbar(message, {
          variant: "info",
          ...commonOptions,
        })
      }

      return {
        resultHandler,
        infoSender,
        onlyErrorHandler,
      }
}