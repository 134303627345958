import SongLists from './SongsList'
import './App.css';
import AllMusicAlbums from './allmusic/AllMusicAlbums';
import {Top} from './Top'
import {BrowserRouter, Routes, Route, useParams} from 'react-router-dom'
import { RenamePlayListsButton } from './RenamePlayLists';
import VersionFooter from './VersionFooter';
import InformationFooter from './InformationFooter';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme()

function App() {
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
    <Top></Top>
    <div className="App">
      <Routes>
      {/* https://stackoverflow.com/questions/70005601/alternate-way-for-optional-parameters-in-v6
        There's no optional parameters in router v6 for now.
        We have to duplicate routes
      */}
        <Route path="/in/:provider/" element={<InputProviders/>}>
        </Route>
        <Route path="/in/:provider/:rest" element={<InputProviders/>}>
        </Route>
        <Route path="/player" element={<SongLists/>}>
        </Route>
        <Route path="/rename" element={ <RenamePlayListsButton/>}>
        </Route>
      </Routes>
    </div>
    <InformationFooter/>
    <VersionFooter></VersionFooter>
    </BrowserRouter>
    </ThemeProvider>
  );
}

function InputProviders() {
  let {provider, rest} = useParams<{provider: string, rest: string}>()
  if (!rest) {
    rest = ""
  }
  switch (provider) {
    case "allmusic":
      return <AllMusicAlbums date={rest}/>
    default:
      return <b>Nothing found</b>
  }
}

export default App;
