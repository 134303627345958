import { resultType, SpotifyPagedTracksResult} from "./types";

declare global {
  interface Window { deviceId: any; }
}


type SpotifyApiParameters = {
  getToken: (() => string) | (() => Promise<string>)
}

type SpotifyAPIFetchParameters = {
  method?: string
  body?: string
}

class SpotifyApi {
  private cacheToken: string | Promise<string> = ""
  private getToken: (() => string) | (() => Promise<string>)

  constructor(params:SpotifyApiParameters) {
    this.getToken = params.getToken
    this.cacheToken = this.getToken()
  }

  public async spotifyFetch(url: string, params?: SpotifyAPIFetchParameters): Promise<Response> {
    const fParams = {
      ...params,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.cacheToken}`
      },
    }

    const resp = await fetch(url, fParams)
    console.debug("First try", resp)

    if (resp.status !== 401 && resp.status !== 400) {
      return resp
    }
    // If unauthorized, refresh token and try again
    this.cacheToken = await this.getToken()

    const fParams2 = {
      ...params,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.cacheToken}`
      },
    }
    const resp2 = await fetch(url, fParams2)
    console.debug("Second try", resp2)

    return resp2

  }
}
const sAPI = new SpotifyApi({
  getToken: async() => {
    // We don't want to follow redirects
    //  because if we're not authenticated,
    //  it will send us to to spotify domain
    //  resulting in an error because of CORS.
    const resp = await fetch("/token", {redirect: "manual"})
    if (resp.status === 400 || resp.status === 401) {
      console.log("Error")
      return ""
    }
    const txt = await resp.text()
    return txt
  }
})

async function playSomething(body: string) {
  let deviceId = await currentDevice()
  console.log("device id", deviceId)
  let resp = sAPI.spotifyFetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, {
    method: 'PUT',
    body: body,
  })

  return resp

}

export async function playSpotifyAlbum(albumId: string, resCallback?: (res: resultType) => void) {
  let resp = await playSomething(JSON.stringify(
    {
      context_uri: `spotify:album:${albumId}`,
    }
  ))

  if (resCallback) {
    switch(resp.status) {
      case 404:
        resCallback({type: "Error", message: "The device was not found"})
        break;
      case 403:
        resCallback({type: "Error", message: "Something went wrong.\nMaybe there's a problem with your premium account.\nTry to reload the page"})
        break;
      // Spotify is now returning a 202
      case 202:
      case 204:
        resCallback({type: "Ok", message: "Play album command success"})
        break
      default:
        resCallback({type: "Error", message: "Something went wrong.\nCode response is " + resp.status})
    }
  }
}

async function currentDevice(): Promise<string> {
  const resp = await sAPI.spotifyFetch(`https://api.spotify.com/v1/me/player`, {
    method: 'GET',
  })

  // If not active player, use this app's player
  if (resp.status === 204) {
    return window.deviceId
  }
  let json = await resp.json()

  return json.device?.id

}

export async function playSpotifyTrack (trackId: string, resCallback?: (res: resultType) => void) {
  let resp = await playSomething(JSON.stringify(
    {
      uris: [
        `spotify:track:${trackId}`,
      ],
    }
  ))

  if (resCallback) {
    switch(resp.status) {
      case 404:
        resCallback({type: "Error", message: "The device was not found"})
        break;
      case 403:
        resCallback({type: "Error", message: "Something went wrong.\nMaybe there's a problem with your premium account.\nTry to reload the page"})
        break;
      // Spotify is now returning a 202
      case 202:
      case 204:
        resCallback({type: "Ok", message: "Play track command success"})
        break
      default:
        resCallback({type: "Error", message: "Something went wrong.\nCode response is " + resp.status})
    }
  }
}


export async function queueSpotifyTrack (trackId: string, resCallback?: (res: resultType) => void) {
  let deviceId = await currentDevice()
  const trackUri = `spotify:track:${trackId}`

  const resp = await sAPI.spotifyFetch(`https://api.spotify.com/v1/me/player/queue?device_id=${deviceId}&uri=${trackUri}`, {
    method: 'POST',
  })

  if(resCallback) {

    switch(resp.status) {
      case 404:
        resCallback({type: "Error", message: "The device was not found"})
        break;
      case 403:
        resCallback({type: "Error", message: "Something went wrong.\nMaybe there's a problem with your premium account.\nTry to reload the page"})
        break;
      // Spotify is now returning a 200
      case 200:
      case 202:
      case 204:
        resCallback({type: "Ok", message: "Track queued"})
        break
      default:
        resCallback({type: "Error", message: "Something went wrong.\nCode response is " + resp.status})
     }
  }
}

async function getAlbumName(spotifyId: string){
  let responseAlbum = await sAPI.spotifyFetch(`https://api.spotify.com/v1/albums/${spotifyId}`)
  let jsonAlbum = await responseAlbum.json()
  return jsonAlbum.name
}

export async function queueSpotifyAlbum (albumId: string, resCallback: (res: resultType) => void) {
  const albumName = await getAlbumName(albumId)

  let responseTracks = await sAPI.spotifyFetch(`https://api.spotify.com/v1/albums/${albumId}/tracks`)

  let json = await responseTracks.json()

  let allresults : resultType[] = [];

  let aggregateErrors = (res:resultType) => {
    allresults.push(res);
  }

  // With foreach we'd have to define functions as async
  // and so we dind't really wait for it
  for (let i=0; i < json.items.length;i++) {
    await queueSpotifyTrack(json.items[i].id, aggregateErrors)
  }

  if(resCallback) {
    const errorResults = allresults.filter((res) => res.type === "Error")
    const errorLength = errorResults.length
    const hasError = errorLength > 0
    if (hasError) {
      resCallback({type: "Error", message: "Error adding " + errorLength + " tracks from '" + albumName + "'"})
    } else {
      resCallback({type: "Ok", message: "Album queued: '" + albumName + "'"})
    }

  }
}


export async function pausePlayer(resCallback?: (res: resultType) => void) {
  let deviceId = await currentDevice()
  const resp = await sAPI.spotifyFetch(`https://api.spotify.com/v1/me/player/pause?device_id=${deviceId}`, {
    method: 'PUT',
  })

  if(resCallback) {
    switch(resp.status) {
      // Spotify is now returning a 200
      case 200:
      case 202:
      case 204:
        resCallback({type: "Ok", message: "Pause command success"})
        break
      case 404:
        resCallback({type: "Error", message: "The device was not found"})
        break
      case 403:
        resCallback({type: "Error", message: "Are you logged in? Or maybe your account is not premium"})
        break
      default:
        resCallback({type: "Error", message: "There was an error. Code: " + resp.status + " " + resp.statusText})
    }
  }
}

export async function nextTrack(resCallback?: (res: resultType) => void) {
  let deviceId = await currentDevice()
  const resp = await sAPI.spotifyFetch(`https://api.spotify.com/v1/me/player/next?device_id=${deviceId}`, {
    method: 'POST',
  })

  if(resCallback) {
    switch(resp.status) {
      // Spotify is now returning a 200
      case 200:
      case 202:
      case 204:
        resCallback({type: "Ok", message: "Next Track command success"})
        break
      case 404:
        resCallback({type: "Error", message: "The device was not found"})
        break
      case 403:
        resCallback({type: "Error", message: "Are you logged in? Or maybe your account is not premium"})
        break
      default:
        resCallback({type: "Error", message: "There was an error. Code: " + resp.status + " " + resp.statusText})
    }
  }
}

export async function searchPaginatedSongs(searchTerm: string, offset: number = 0, limit: number = 20, resCallback?: (res: resultType) => void): Promise<SpotifyPagedTracksResult> {
  console.log("Searching for: ", searchTerm, "offset: ", offset, "limit: ", limit);
  searchTerm = encodeURIComponent(searchTerm).replace(/'/g, "")
  if (searchTerm?.trim() !== ""){

    let response = await sAPI.spotifyFetch(`https://api.spotify.com/v1/search?q=` + searchTerm + `&type=track&limit=` + limit + `&offset=` + offset, {
      method: 'GET',
    })

    if(resCallback && response.status !== 200) {
      switch (response.status) {
        case 400:
          resCallback({type: "Error", message: "Please login before do any search"})
          break
        default:
          resCallback({type: "Error", message: "Something went wrong.\nCode response is " + response.status})
      }
      return {total: 0, items: []}
    }
    let json = await response.json()
    // https://developer.spotify.com/documentation/web-api/reference/#endpoint-search
    // Maximum offset (including limit): 1,000.
    if (json?.tracks?.total > 1000) {
      json.tracks.total = 1000
    }

    if (json?.tracks?.total === 0 && resCallback) {
      resCallback({type: "Ok", message: "Nothing found"})
    }

    return json?.tracks
  }
  return {
    total: 0,
    items: [],
  }
}
