import { IconButton } from "@mui/material"
import PauseIcon from '@mui/icons-material/Pause';
import { useSnackBarHandleResponse } from "../hooks";
import {pausePlayer} from '../spot';

export default  function PauseButton() {
    const {onlyErrorHandler} = useSnackBarHandleResponse()

    return <IconButton title="Pause" color="primary" onClick={() => pausePlayer(onlyErrorHandler)}>
        <PauseIcon></PauseIcon>
    </IconButton>
}