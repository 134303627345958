import { IconButton } from "@mui/material"
import { useSnackBarHandleResponse } from "../hooks"
import { queueSpotifyAlbum } from "../spot"
import QueueMusic from '@mui/icons-material/QueueMusic';



export default function EnqueueAlbum({spotifyId}:{spotifyId:string}) {
    const {infoSender, resultHandler} = useSnackBarHandleResponse()

    return <IconButton
        title="Enqueue album"
        color="primary"
        onClick={() => {
            infoSender("Sent album to the queue")
            queueSpotifyAlbum(spotifyId, resultHandler)
    }}>
        <QueueMusic/>
    </IconButton>
}