import React from 'react';

function InformationFooter() {
    return (
        <footer id="sticky-footer" className="footer mt-auto py-3 text-center">
            <div>Esta página contiene iconos diseñados por <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</a></div>
        </footer>
    );
}

export default InformationFooter;