import { Jumbotron } from "./Jumbotron"
import { NavBar } from "./NavBar"
import './Top.css'
import React from 'react'

function useUser(p: string) {
    const [User, setUser] = React.useState(p)
    React.useEffect(() => {
            let f = async () => {
                let resp = await fetch("/api/user")
                let js  = await resp.json()
                console.log("jsusername", js)
                if (js?.id !== "" && js?.id !== undefined) {
                    setUser(js?.id)
                }
            }
            f()
        }
    )

    return User
} 


export function Top() {
    let user = useUser("")
    return <>
        <NavBar user={user}></NavBar>
        <Jumbotron></Jumbotron>
    </>
}