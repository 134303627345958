import MusicCard from './MusicCard';
import { Link } from 'react-router-dom';
import { SimpleAlbumPlayer } from './SimpleAlbumPlayer';
import { addAlbumAsPlayList } from './api';
import { SpotifyAlbum } from './types';
import { IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

type AllMusicAlbumProperties = {
    ReviewSummary: string
    Rating: string
    Genres: string[]
}

export default function AllMusicAlbumMusicCard({album}: {album: SpotifyAlbum & AllMusicAlbumProperties}) {
    return (
        <>
        <MusicCard
            image={{src: album.images[0].url}}
            title={<Link to={"/player?s=" + encodeURIComponent("artist:\"" + album.artists[0].name + "\" album:\"" + album.name + "\"")}>{album.name}</Link>}
            properties={[
                {
                    name: 'Artists',
                    value: <>{album.artists.map((a) => <Link title={"Albums for " + a.name} to={"/player?s=" + encodeURIComponent("artist:\"" + a.name + "\"")}>{a.name}</Link>).reduce((prev, curr) => <>{prev}, {curr}</>)}</>
                },
                {
                    name: 'Rating',
                    value: album.Rating,
                },
                {
                    name: 'Genres',
                    value: album.Genres?.join(", "),
                },
                {
                    name: 'Release date',
                    value: album.release_date,
                },
                {
                    name: 'Review',
                    value: album.ReviewSummary,
                },
            ]}
        >
            <IconButton title="Create playlist" color="primary" onClick={() => addAlbumAsPlayList(album.id)}>
                <AddCircleOutlineIcon/>
            </IconButton>
            <SimpleAlbumPlayer spotifyId={album.id}></SimpleAlbumPlayer>
        </MusicCard>
        </>
    )
}