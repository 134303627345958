import {Button} from '@mui/material'
import { renamePlayLists } from './api'
import { useSnackBarHandleResponse } from './hooks'

export function RenamePlayListsButton () {
    const {infoSender, resultHandler} = useSnackBarHandleResponse()
    return <Button
        variant="contained"
        color="primary"
        onClick={() => {
            infoSender("Start renaming playlists")
            renamePlayLists(resultHandler)
        }}
    >Rename PlayLists</Button>
}