import  './MusicCard.css'

type CardMainImage = {
    src: string
    alt?: string
}

type MusicCardProps = {
    title?: string | JSX.Element | JSX.Element[]
    properties?: MusciCardPropertyProps[]
    image?: CardMainImage
    children?: JSX.Element | JSX.Element[]
}

type MusciCardPropertyProps = {
    name?: string,
    value: string | JSX.Element | JSX.Element[]
}

function MusicCardProperty({name, value}:MusciCardPropertyProps) {
    return (
        <>
            {(name !== undefined) &&
                <><span className="fw-bold">{name}</span>: </>}{value}
        </>
    )
}

export default function MusicCard(props: MusicCardProps) {
    return (
        <div className="col-md-4">
            <div className="card" style={{ width: 18 + "rem" }}>
                {/* We had to disable eslint jsx-a11y/img-redundant-alt check in package.json
                because if fails due to alt value. See
                https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/755 */}
                <img id="cardimage1" className="card-img-top" src={props.image?.src} alt={props.image?.alt} />
                <div className="card-body">
                    <h5 className="card-title">
                        <MusicCardProperty value={props.title || ""} />
                    </h5>
                    <ul className="list-group list-group-flush">
                        {props.properties?.map((p) => {
                            return (
                                <li key={p.name} className="list-group-item text-start">
                                    <MusicCardProperty
                                        name={p.name}
                                        value={p.value}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                    {props.children}
                </div>
            </div>
        </div>
    )
}