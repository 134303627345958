import React from 'react'
import { AppBar, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { Link, NavLink} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountCircle } from '@mui/icons-material';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import InputIcon from '@mui/icons-material/Input';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const UserMenu = ({username}: {username: string}) => {
    const [anchorEl, setanchorEl] = React.useState<any>(null)

    const handleClick = (event:any) => {setanchorEl((prev: any) => {
        if (prev === null) {
            return event.currentTarget
        } else {
            return null
        }
    })}
    
    return <>
    
    <IconButton
        onClick={handleClick}
    >
        <AccountCircle></AccountCircle>
        <Typography variant="body1">{username}</Typography>
    </IconButton>
    <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        variant="menu"
        onClose={handleClick}
    >
        <MenuItem>Profile</MenuItem>
        <MenuItem><a href="/logout">Log Out</a></MenuItem>
    </Menu>
    </>
}

  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: "#feffd8 !important",
    },
  }));
export function NavBar({user}: {user:string}) {
    const classes = useStyles();

    const [openDrawer, setopenDrawer] = React.useState(false)

    let menuItems = user === "" ? [] :  [{
            name: "Inputs",
            link: "/in/allmusic",
            icon: <InputIcon/>,
          },{
            name: "Search",
            link: "/player",
            icon: <PlayCircleOutlineIcon/>,
        },
        {
          name: "Rename PlayLists",
          link: "/rename",
          icon: <AutorenewIcon/>,
      },
    ]
    let loginUser = user === "" ? <a className="nav-link active" href="/auth">
        <Button
            sx={{color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#bec",}}
            variant="outlined"
            startIcon={<img width="25px" alt="Spotify logo" src="/spotify.svg"></img>}
        >
            Log In
        </Button>
        </a> : <UserMenu username={user}/>
    let menu = user === "" ? null : <IconButton edge="start" sx={{marginRight: 2}}  color="inherit" aria-label="menu">
        <MenuIcon onClick={() => setopenDrawer(true)}/>
        </IconButton>;

    return <AppBar sx={{backgroundColor: "#feffd8", color: "rgb(0, 123, 255)", flexGrow: 1}} position="sticky">
    <Toolbar variant="dense">
      <IconButton edge="start" sx={{marginRight: 2}} color="inherit" aria-label="logo">
        <Link to="/" className="nav-link navbar-brand">Q</Link>
      </IconButton>
      {
        menu
      }
      <div style={{flexGrow: 1}} />
      {
          loginUser
      }
    </Toolbar>
    <Drawer
      classes={classes}
      open={openDrawer}
      onClose={() => setopenDrawer(false)}
    >
      <List>
        {
         menuItems.map((item) => {
          return <ListItem>
            <ListItemText>
              <NavLink onClick={() => setopenDrawer(false)} className="nav-link" to={item.link}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                {item.name}
              </NavLink>
            </ListItemText>
          </ListItem>
          })
        }
      </List>
    </Drawer>
  </AppBar>
}