import { resultType } from "./types"

export function addAlbumAsPlayList(spotifyId: string) {
    // Create PlayList
    fetch("/api/addPlayListFromAlbum?id="+spotifyId)
    // List tracks of album
    // Add tracks to playlist
}


export async function renamePlayLists(resCallback?: (res: resultType) => void) {
    let resp = await fetch("/api/renameplaylists")

    if(resCallback) {
        switch(resp.status) {
            case 204:
                resCallback({type: "Ok", message: "Rename playlists command success"})
                break;
              default:
                resCallback({type: "Error", message: "Something went wrong renaming playlists.\nCode response is " + resp.status})
        }
    }
}