var deviceReady = false;

/*
 * Spotify code
*/


var playerName = 'Q-ify Player';
var player;


window.onSpotifyWebPlaybackSDKReady = () => {
  fetch("/token")
  .then(response => {
    if (response.status === 400 || response.status === 401) {
      window.location = "/auth"
    }
    return response.text()
  }).then(txt => {
    window.token = txt
    }
  )
player = new window.Spotify.Player({
    name: playerName,
    getOAuthToken: cb => { cb(window.token);}
  });

  // Error handling
  player.addListener('initialization_error', ({ message }) => { console.log(message);console.error(message); });
  player.addListener('authentication_error', ({ message }) => { console.error(message); });
  player.addListener('account_error', ({ message }) => { console.error(message); });
  player.addListener('playback_error', ({ message }) => { console.error(message); });

  // Playback status updates
  player.addListener('player_state_changed', state => { console.log(state); });

  // Ready
  player.addListener('ready', ({ device_id }) => {
    console.log('Ready with Device ID', device_id);
    deviceReady = true;
    console.log(deviceReady);
    window.deviceId = device_id;
  });

  // Not Ready
  player.addListener('not_ready', ({ device_id }) => {
    console.log('Device ID has gone offline', device_id);
  });

  // Connect to the player!
  player.connect().then(success => {
    if (success) {
      console.log("Player connected")
      console.log(player);
    }
  });
};