import { IconButton } from "@mui/material";
import { nextTrack } from "../spot";
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { useSnackBarHandleResponse } from "../hooks";

export default function NextTrackButton () {
    const {onlyErrorHandler} = useSnackBarHandleResponse()

    return <IconButton title="Next track" color="primary" onClick={() => nextTrack(onlyErrorHandler)}>
        <SkipNextIcon></SkipNextIcon>
    </IconButton>
}