import { IconButton } from "@mui/material"
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';

import { useSnackBarHandleResponse } from "./hooks";

import {
    playSpotifyTrack,
    queueSpotifyTrack}
from './spot';
import NextTrackButton from "./components/NextTrackButton";
import PauseButton from "./components/PauseButton";

export function SimpleTrackPlayer({spotifyId}:{spotifyId:string}) {
    const {onlyErrorHandler, resultHandler} = useSnackBarHandleResponse()
    return (
        <p>
            <PauseButton/>
            <IconButton title="Play" color="primary" onClick={() => playSpotifyTrack(spotifyId, onlyErrorHandler)}>
                <PlayArrowIcon></PlayArrowIcon>
            </IconButton>
            <NextTrackButton/>
            <IconButton title="Enqueue track" color="primary" onClick={() => queueSpotifyTrack(spotifyId, resultHandler)}>
                <QueueMusicIcon></QueueMusicIcon>
            </IconButton>
        </p>
    )
}